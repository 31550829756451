import { Fab, useTheme, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NavigationIcon from '@mui/icons-material/Navigation';

const BackToTop = () => {
  const [isIn, setIsIn] = useState(false);
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    const handleScroll = () => {
      const newIsIn = window.scrollY !== 0;
      if (newIsIn !== isIn) {
        setIsIn(newIsIn);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isIn]);

  return (
    <Zoom
      in={isIn}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${isIn ? transitionDuration.exit : 0}ms`,
      }}
      unmountOnExit
    >
      <Fab
        size="small"
        sx={{
          position: 'fixed',
          bottom: '16px',
          right: '16px',
          backgroundColor: "#FFCC80"
        }}
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
      >
        <NavigationIcon />
      </Fab>
    </Zoom>

  )
}

export default BackToTop;
