import { Backdrop, createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import './App.css';
import DawnOfCrypton from './pages/dawnOfCrypton';
import '@fontsource/cinzel/400.css';
import '@fontsource/cinzel/700.css';
import "@fontsource/oxygen/400.css";

const theme = createTheme();

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Backdrop open={true} sx={{ zIndex: 0, backgroundColor: "rgba(0, 0, 0, 0.6)" }} />
        <DawnOfCrypton />
      </ThemeProvider>
    </div>
  );
}

export default App;
