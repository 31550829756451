import { ArrowDownward } from '@mui/icons-material';
import { Avatar, Button, Link, Stack, SxProps, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  text: string;
  rotatingText: string[];
  colors: string[];
  icon?: string;
  onClick?: () => void;
  link?: string;
  sx?: SxProps
}
const CommonRotatingButton: FC<Props> = ({ text, rotatingText, colors, icon, onClick, link, sx }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if(currentIndex === rotatingText.length - 1) {
        setCurrentIndex(0);
      }
      else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [currentIndex, rotatingText.length])
  return (
    <Button
      onClick={onClick}
      startIcon={icon
        ? <Avatar src={icon} sx={{ width: '24px', height: '24px', borderRadius: '0px' }} />
        : <ArrowDownward sx={{ color: colors[currentIndex] }} style={{ width: '48px', height: '48px' }} />}
      component={link ? Link : Button}
      href={link}
      target={link ? "_blank" : ""}
      sx={{
        borderRadius: "36px",
        borderStyle: "solid",
        borderWidth: '2px',
        borderColor: "#FFCC80",
        background: "#000000",
        minWidth: "85px",
        paddingLeft: "30px",
        paddingRight: "40px",
        paddingY: "10px",
        ...sx
      }}
    >
      <Stack>
        <Typography
          sx={{
            fontFamily: 'Cinzel',
            lineHeight: '16px',
            fontSize: "16px",
            fontWeight: 700,
            textAlign: 'center',
            color: "#FFF",
            marginBlockStart: "0px",
            marginBlockEnd: "0px",
            marginTop: "2px",
            marginBottom: "8px"
          }}
        >
          {text}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Cinzel',
            lineHeight: '16px',
            fontSize: "16px",
            fontWeight: 700,
            textAlign: 'center',
            color: colors ? colors[currentIndex] : "#FFF",
            marginBlockStart: "0px",
            marginBlockEnd: "0px",
            marginTop: "2px",
          }}
        >
          {rotatingText[currentIndex]}
        </Typography>
      </Stack>
    </Button>
  )
}

export default CommonRotatingButton