import { Box, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import CULogoGold from '../../assets/images/CU-Logo-Gold.png';
import Map from '../../assets/images/Map.png';
import Carpentry from '../../assets/images/Carpentry.png';
import CrystalPage from '../../assets/images/CrystalPage.png';
import Elves from '../../assets/images/Elves.png';
import Herbalism from '../../assets/images/Herbalism.png';
import Potions from '../../assets/images/Potions.png';
import Resources from '../../assets/images/Resources.png';
import Rewards from '../../assets/images/Rewards.png';
import Tools from '../../assets/images/Tools.png';
import OpenSea from '../../assets/images/OpenSea-Logomark-Blue.png';
import Windows from '../../assets/images/platforms/windows.png';
import Android from '../../assets/images/platforms/android.png';
import Linux from "../../assets/images/platforms/linux.png";
import BackToTop from '../../components/BackToTop';
import CommonButton from '../../components/CommonButton';
import CommonRotatingButton from '../../components/CommonRotatingButton';
import MobileScreenshot from '../../components/MobileScreenshot';


const Title = styled(Typography)(() => ({
  fontFamily: 'Cinzel',
  lineHeight: '44px',
  fontSize: "44px",
  fontWeight: 700,
  textAlign: 'center',
  overflowWrap: 'break-word',
  color: "#F2BC6A",
  textTransform: "uppercase",
  marginBlockStart: "0px",
  marginBlockEnd: "0px",
}))

const SubHeader = styled(Title)(() => ({
  fontFamily: 'Cinzel',
  lineHeight: '21.57px',
  fontSize: "16px",
  fontWeight: 400,
  color: "#FFF",
}))

const BoldSubHeader = styled('span')(() => ({
  fontFamily: 'Cinzel',
  lineHeight: '21.57px',
  fontSize: "16px",
  fontWeight: 700,
  color: "#FFF",
  textTransform: "uppercase",
  marginBlockStart: "0px",
  marginBlockEnd: "0px",
}))

const Paragraph = styled(Typography)(() => ({
  fontFamily: 'Oxygen',
  lineHeight: '24px',
  fontSize: "16px",
  textAlign: 'center',
  overflowWrap: 'break-word'
}))

const CenterBox = styled(Box)(() => ({
  justifyContent: 'center',
  alignItems: "center",
  display: "flex"
}))

const DawnOfCrypton = () => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.down("md"))
  const platforms = [
    { platform: "android", icon: Android, color: "#3ddc84"},
    { platform: "windows", icon: Windows, color: "#00adef"},
    { platform: "linux", icon: Linux, color: "#ebac03"},
  ]

  const sections = [
    {
      title: "the basics",
      image: [Map],
      paragraph: "Dawn of Crypton (DoC) is a 2D minigame prequel to the Cosmic Universe MMORPG. Players using their" +
                 " Wizards, Elves, and Land NFTs get the opportunity to gather resources and other valuable items" +
                 " ahead of the release of our flagship MMORPG.",
      paragraph2: "There are two major aspects, Elven Adventures and Profession Expeditions, along with a few" +
                 " microgames within the prequel gameplay experience. Players get to unlock tokenized game assets" +
                 " (NFTs) that will be transferable and usable in the MMORPG.",
      buttons: [],
    },
    {
      title: "elven adventures",
      image: [Elves, Herbalism],
      screenshot: true,
      paragraph: "The Cosmic Elves that are being woken from their slumber are being tasked with the preparation of the island of Aelfaria. Elves can undertake adventures to Aelfaria to train for their Professions (all 12 that Wizards can already train in) in their native environment. The more advanced they become, the longer each adventure will take.",
      buttons: [
        { text: "mint", link: "https://marketplace.cosmicuniverse.io/mint" },
        { text: "buy", icon: OpenSea, link: "https://opensea.io/collection/cu-cosmic-elves" }
      ],
    },
    {
      title: "profession expeditions",
      image: [CrystalPage, Carpentry, Herbalism],
      screenshot: true,
      paragraph: "Players can use their Wizards and Elves, along with land plots, to embark on special expeditions" +
                 " where they begin earning resources and materials for their chosen Professions. These tokenized" +
                 " game assets (NFTs) will be transferable to, and usable in, the MMORPG.",
      buttons: [],
    },
    {
      title: "tools",
      image: [Tools],
      paragraph: "Each character Profession will have its own unique Profession Tools. With these tools, players can" +
                 " unlock exclusive resources, items, and buildable structures, as well as open up new gameplay" +
                 " experiences. Rarer and sturdier tools will also be rewarded during gameplay.",
      buttons: [],
    },
    {
      title: "resources",
      image: [Resources],
      paragraph: "Resources not found in the wild in Cosmic Universe can be unlocked by specific Professions. Each" +
                 " Profession provides an avenue to unique resources being unlocked and received, and usable" +
                 " in the MMORPG.",
      buttons: [],
    },
    {
      title: "potions & elixirs",
      image: [Potions],
      paragraph: "Potions allow players to perform feats such as decrease time to complete Expeditions and increase " +
                 "the chances of completing an Expedition. Elixirs may increase the amount of rewards a player" +
                 " receives or can guarantee the winning of certain items.",
      buttons: [],
    },
    {
      title: "rewards",
      image: [Rewards],
      paragraph: "Numerous and varied rewards are winnable within Dawn of Crypton, many of which are transferable to" +
                 " the Cosmic Universe MMORPG.",
      buttons: [],
    }
  ]

  return(
    <CenterBox id="DawnOfCryptonPage" sx={{ padding: 4, maxWidth: "lg", zIndex: 1 }}>
      <BackToTop />
      <Stack spacing={13} sx={{ marginY: 4, alignItems: "center" }}>
        <Stack>
          <img src={CULogoGold} alt="logo" style={{ width: '100%', maxWidth: '400px'  }} />
          <Title sx={{ lineHeight: '47px', fontSize: "34.5px", fontWeight: 400 }}>
            dawn of crypton
          </Title>
          <SubHeader sx={{ display: 'inline-block' }}>
            A
            <BoldSubHeader>{' '}prequel{' '}</BoldSubHeader>
            to the Cosmic Universe MMORPG
          </SubHeader>
        </Stack>
        <CenterBox>
          <CommonRotatingButton
            text="download for"
            rotatingText={platforms.map(p => p.platform)}
            colors={platforms.map(p => p.color)}
            onClick={() => window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: "smooth" }) }
          />
        </CenterBox>
        {sections.map((s, i) => (
          <Stack
            spacing={7}
            key={i}
            sx={{ justifyContent: "center", alignItems: "center" }}
            direction={desktop ? "column" : ( i % 2 === 0 ? "row" : "row-reverse")}
          >
            <CenterBox>
              {s.screenshot ? (
                <MobileScreenshot src={s.image} desktop={desktop} />
              ) : (
                <img src={s.image[0]} alt="img" style={{ width: '100%', minWidth: '325px', maxWidth: desktop ? '700px' : '700px' }} />
              )}
            </CenterBox>
            <Stack spacing={4} sx={desktop ? {} : { maxWidth: "35%" }}>
              <Title>{s.title}</Title>
              <Paragraph>{s.paragraph}</Paragraph>
              {s.paragraph2 && <Paragraph>{s.paragraph2}</Paragraph>}
              {s.buttons && (
                <Stack direction="row" sx={{ justifyContent: "space-around" }}>
                  {s.buttons.map((b, i) => (
                    <CommonButton key={i} text={b.text} icon={b.icon} link={b.link} />
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        ))}
        <Stack spacing={4}>
          <Title>download</Title>
          <Paragraph sx={{  marginBottom: '16px !important' }}>Dawn of Crypton is compatible with</Paragraph>
          <Stack spacing={4} direction={desktop ? "column" : "row"} sx={{ justifyContent: "center", marginTop: 6 }}>
            {platforms.map((p, i) => (
              <CommonButton key={i} text={p.platform} icon={p.icon} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </CenterBox>
  )
}
// 32
// header bottom 32
// section 32
// Divider depends
export default DawnOfCrypton;