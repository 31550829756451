import { Box } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  src: string[],
  desktop: boolean
}

const MobileScreenshot: FC<Props> = ({ src, desktop }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if(currentIndex === src.length - 1) {
        setCurrentIndex(0);
      }
      else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [currentIndex])


  return (
    <Box sx={{ overflow: 'hidden', borderRadius: "24px", borderColor: "#F2BC6A", borderWidth: '1px', borderStyle: "solid" }}>
      <img src={src[currentIndex]} alt="screenshot" style={{ width: '100%', minWidth: '325px', maxWidth: desktop ? '700px' : '700x' }} />
    </Box>
  )
}

export default MobileScreenshot;