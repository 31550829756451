import { Avatar, Button, Link, SxProps, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  text: string;
  icon?: string;
  onClick?: () => void;
  link?: string;
  sx?: SxProps
}
const CommonButton: FC<Props> = ({ text, icon, onClick, link, sx }) => {
  return (
    <Button

      onClick={onClick}
      startIcon={icon ? <Avatar src={icon} sx={{ width: '24px', height: '24px', borderRadius: '0px' }} /> : undefined}
      component={link ? Link : Button}
      href={link}
      target={link ? "_blank" : ""}
      sx={{
        borderRadius: "36px",
        borderStyle: "solid",
        borderWidth: '2px',
        borderColor: "#FFCC80",
        background: "#000000",
        minWidth: "85px",
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingY: "10px",
        ...sx
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cinzel',
          lineHeight: '16px',
          fontSize: "16px",
          fontWeight: 700,
          textAlign: 'center',
          color: "#FFF",
          marginBlockStart: "0px",
          marginBlockEnd: "0px",
          marginTop: "2px",
        }}
      >
        {text}
      </Typography>
    </Button>
  )
}

export default CommonButton